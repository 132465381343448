<template>
  <v-container fluid  class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form>
        <v-card class="elevation-12">
          <v-toolbar color="#043775" dark flat>
            <v-toolbar-title>Auth</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field label="OTP" v-model="otp" name="otp" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#043775" class="white--text" @click="triggerSubmit">Submit</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import router from '../router'

export default {
  name: 'Login',
  data: () => ({
    otp: ''
  }),
  methods: {
    ...mapActions({
      submitotp: 'session/submitotp'
    }),
    triggerSubmit () {
      const payload = { sessionId: this.$route.params.sessionId, otp: this.otp }
      this.submitotp(payload).then(response => {
        if (response.data.error) {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: response.data.message }, { root: true })
        } else {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'success', message: response.data.message }, { root: true })
          router.push(`/pin/${this.$route.params.sessionId}`).catch(error => {
            console.log(error)
          })
        }
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        } else {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response ? error.response.data.message : 'Failed to connect to backend' }, { root: true })
        }
      })
    }
  }
}
</script>
